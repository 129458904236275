import { FC, useEffect, useState } from "react";
import SectorMoves from "../sector-moves/SectorMoves";
import Grid from "@mui/material/Grid";
// import IndexesChart from "../indexes-chart/IndexesChart";
import { getIndexes } from "@/controllers/stock-indexes";
import ToggleButton from "@mui/material/ToggleButton";
import { Tabs, Typography } from "@mui/material";
import { Exchange } from "@/models/instruments";
import useMediaQuery from "@mui/material/useMediaQuery";
import Skeleton from "../skeleton/Skeleton";
import { useRecoilValue } from "recoil";
import { dividerState, negativeState, positiveState, textColorState } from "@/lib/store";
import styles from "./StockIndexes.module.scss";
import dynamic from "next/dynamic";

const IndexesChart = dynamic(() => import("@/components/indexes-chart/TVIndexesChart"), { ssr: false });

const WrappedToggleButton = (props) => {
    let newProps = { ...props };
    delete newProps.selectionFollowsFocus;
    delete newProps.textColor;
    delete newProps.indicator;

    return <ToggleButton {...newProps} />;
};
WrappedToggleButton.muiName = ToggleButton.name;

const StockIndexes: FC = () => {
    const [indexes, setIndexes] = useState([]);
    const [activeIndexName, setActiveIndexName] = useState("S&P 500");
    const [activeIndex, setActiveIndex] = useState<Exchange>();

    const [isLoading, setIsLoading] = useState(true);

    const isMobile = useMediaQuery("(max-width: 768px)");

    const dividerColor = useRecoilValue(dividerState);
    const textColor = useRecoilValue(textColorState);
    const positive = useRecoilValue(positiveState);
    const [priceVariations, setPriceVariations] = useState<any>({});
    const negative = useRecoilValue(negativeState);

    useEffect(() => {
        setIsLoading(true);
        getIndexes()
            .then((data) => {
                const indexesArray = data.rows.map((row) => ({ name: row.name, dailyChange: row.dailyChange, universeId: row.universeId, ISIN: row.webSocketId }));
                setIndexes(indexesArray);
                setActiveIndex(indexesArray.find((row) => row.name === activeIndexName));
            })
            .finally(() => setIsLoading(false));
    }, []);

    const handleIndexChange = (event: React.MouseEvent<HTMLElement>, indexName: string) => {
        setActiveIndexName(indexName);
        setActiveIndex(indexes.find((index) => index.name === indexName));
    };

    return (
        <>
            <style>
                {`
                .MuiTabs-flexContainer {
                    height:100%;
                    display:flex;
                    justify-content:space-between;
                }
                `}
            </style>
            <Grid container spacing={2} columns={12} sx={{ height: "100%" }}>
                <Grid item xs={12} md={2.5}>
                    {isLoading ? (
                        <Skeleton variant="rounded" width="100%" height="100%" loading={isLoading} />
                    ) : (
                        <Tabs
                            orientation="vertical"
                            variant={"fullWidth"}
                            value={activeIndexName}
                            onChange={handleIndexChange}
                            sx={{
                                borderRight: 1,
                                borderColor: dividerColor,
                            }}
                            className={styles.tabs}
                        >
                            {indexes.map((index) => {
                                const indexPriceVariation = priceVariations[index.ISIN]?.["percentage"];
                                return (
                                    <WrappedToggleButton
                                        key={index.name}
                                        value={index.name}
                                        aria-label={index.name}
                                        sx={{ border: 0, display: "flex", justifyContent: "space-between", padding: "1px 10px", height: `calc(90% / ${indexes.length})` }}
                                        className={styles.resize}
                                    >
                                        <Typography variant="body2">{index.name}</Typography>
                                        <Typography variant="body2" fontWeight={600} style={{ color: indexPriceVariation ? (indexPriceVariation > 0 ? positive : negative) : textColor }}>
                                            &nbsp;{indexPriceVariation ? indexPriceVariation?.toFixed(2) + "%" : "-"}&nbsp;
                                        </Typography>
                                    </WrappedToggleButton>
                                );
                            })}
                        </Tabs>
                    )}
                </Grid>
                <Grid item xs={12} md={5.5} height={isMobile ? "350px" : "100%"}>
                    {isLoading ? <Skeleton variant="rounded" width="100%" height="100%" loading={isLoading} /> : <IndexesChart indexes={indexes} activeIndex={activeIndex} setActiveIndex={setActiveIndex} setPriceVariations={setPriceVariations} />}
                </Grid>
                <Grid item xs={12} md={4} height={isMobile ? "350px" : "100%"}>
                    <SectorMoves indexId={activeIndex?.universeId} />
                </Grid>
            </Grid>
        </>
    );
};

export default StockIndexes;
